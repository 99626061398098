import { assign, keys } from 'lodash'

const generateOutputDataObj = (inputDataObj, keyValueMapping) => {
  const outputDataObj = {}
  keys(inputDataObj).forEach(key => {
    const value = inputDataObj[key]
    let newKey = keyValueMapping[key]
    if (!newKey) newKey = key
    assign(outputDataObj, { [newKey]: value })
  })
  return outputDataObj
}

export const journalBookImportAdapter = inputDataObj => {
  return generateOutputDataObj(inputDataObj, {
    glbjncd_id: 'id',
    glbjncd_journalCode: 'journalCode',
    glbjncd_journalName: 'journalName',
    glbjncd_journalRunningNumber: 'journalRunningNumber',
    glbjncd_journalTempDoc: 'journalTempDoc',
    glbjncd_accountItem: 'accountItem',
    glbjncd_isLock: 'isLock',
    glbjncd_deletedAt: 'deletedAt',
    glbjncd_userId: 'userId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    documentIdPattern: 'documentIdPattern'
  })
}

export const journalBookExportAdapter = inputDataObj => {
  return generateOutputDataObj(inputDataObj, {
    id: 'glbjncd_id',
    accountItem: 'glbjncd_accountItem',
    deletedAt: 'deleglbjncd_deletedAttedAt',
    isLock: 'glbjncd_isLock',
    journalCode: 'glbjncd_journalCode',
    journalName: 'glbjncd_journalName',
    journalRunningNumber: 'glbjncd_journalRunningNumber',
    journalTempDoc: 'glbjncd_journalTempDoc',
    userId: 'glbjncd_userId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    documentIdPattern: 'documentIdPattern'
  })
}

export const journalHeaderImportAdapter = inputDataObj => {
  return generateOutputDataObj(inputDataObj, {
    gltjnth_journalNumber: 'documentNumber',
    gltjnth_documentDate: 'documentDate',
    gltjnth_description: 'description',
    gltjnth_remark: 'remark',
    gltjnth_isReverse: 'isReverse',
    gltjnth_itemCount: 'itemCount',
    gltjnth_totalDebit: 'totalDebit',
    gltjnth_totalCredit: 'totalCredit',
    gltjnth_postedAt: 'postedAt',
    gltjnth_isMockPosted: 'isMockPosted',
    gltjnth_deletedAt: 'deletedAt',
    branchId: 'branchId'
  })
}

export const journalHeaderExportAdapter = () => {}

export const journalDetailImportAdapter = inputDataObj => {
  return generateOutputDataObj(inputDataObj, {
    gltjntd_id: 'id',
    gltjntd_journalCode: 'journalCode',
    gltjntd_journalNumber: 'documentNumber',
    gltjntd_accountNumber: 'accountNumber',
    gltjntd_postedDate: 'postedDate',
    gltjntd_transactionDate: 'transactionDate',
    gltjntd_debitAmount: 'debitAmount',
    gltjntd_creditAmount: 'creditAmount',
    gltjntd_remark: 'remark',
    gltjntd_referenceDocument: 'referenceDocument',
    gltjntd_referenceNote: 'referenceNote',
    gltjntd_deletedAt: 'deletedAt',
    gltjntd_postedAt: 'postedAt',
    gltjntd_isMockPosted: 'isMockedPosted',
    gltjntd_srctyp: 'srctyp',
    gltjntd_sequenceNo: 'sequenceNo',
    gltjntd_userId: 'userId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    glmaccn_accountName: 'accountName',
    branchId: 'branchId'
  })
}

export const journalDetailExportAdapter = () => {}

export const journalCompanyImportAdapter = inputDataObj => {
  return generateOutputDataObj(inputDataObj, {
    cmp_idno: 'id',
    cmp_name: 'name',
    cmp_branch: 'branch',
    cmp_adr1: 'address1',
    cmp_adr2: 'address2',
    cmp_prov: 'province',
    cmp_zipc: 'postalCode',
    cmp_teln: 'telephoneNumber',
    cmp_fax: 'faxNumber',
    cmp_txno: 'taxNumber'
  })
}
