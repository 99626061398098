<script>
export default {
  props: {
    deleteModalDisplayStatusProp: Boolean,
    headerDataProp: Object,
    informationDataProp: Object
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    displayJournalName() {
      return (
        (this.headerDataProp &&
          `${this.headerDataProp.journalName} (${this.headerDataProp.journalCode})`) ||
        '-'
      )
    },
    displayDocumentId() {
      return (
        (this.informationDataProp && this.informationDataProp.documentId) || '-'
      )
    },
    displayDocumentNumber() {
      return (
        (this.informationDataProp && this.informationDataProp.documentNumber) ||
        '-'
      )
    }
  },
  watch: {
    deleteModalDisplayStatusProp() {
      this.isOpen = this.deleteModalDisplayStatusProp
    },
    isOpen() {
      if (!this.isOpen) {
        this.$emit('modalClosed')
      }
    }
  },
  methods: {
    buttonClicked(operation) {
      switch (operation) {
        case 'CONFIRM':
          this.$emit('deleteConfirmed')
          this.isOpen = false
          break
        case 'CANCEL':
          this.isOpen = false
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <b-modal
      title="ลบรายการสมุดรายวัน"
      v-model="isOpen"
      header-bg-variant="danger"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
      <b-row class="my-3">
        <!-- trash icon -->
        <b-col
          cols="4"
          class="d-flex justify-content-center align-items-center"
        >
          <b-icon icon="trash" font-scale="5" variant="danger"></b-icon>
        </b-col>

        <!-- information display -->
        <b-col>
          <div class="mb-4">
            <b>คุณแน่ใจที่จะลบรายการนี้หรือไม่</b>
          </div>
          <div class="mb-3">
            <span>สมุดรายวัน : {{ displayJournalName }}</span>
          </div>
          <div class="mb-3">
            <span>เลขที่เอกสาร : {{ displayDocumentId }}</span>
          </div>
          <div class="mb-3">
            <span>เลขที่อ้างอิง : {{ displayDocumentNumber }}</span>
          </div>
        </b-col>
      </b-row>

      <!-- buttons -->
      <div class="d-flex justify-content-end">
        <div class="mr-3">
          <b-button variant="danger" @click="buttonClicked('CONFIRM')">
            ยืนยัน
          </b-button>
        </div>
        <div>
          <b-button variant="outline-danger" @click="buttonClicked('CANCEL')">
            ยกเลิก
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style></style>
