<script>
import _ from 'lodash'

import AccountTransactionTable from '@/components/table/AccountTransactionTable/Index.vue'

export default {
  components: { AccountTransactionTable },
  props: {
    informationProp: Object,
    tableDetailsProp: Array,
    paginationInfoProp: Object
  },
  data() {
    return {
      isLoading: false,
      documentNumber: null,
      totalDebit: 0,
      totalCredit: 0,
      tableDetails: [],
      paginationInfo: null
    }
  },
  watch: {
    tableDetailsProp() {
      this.isLoading = true
      this.tableDetails = this.tableDetailsProp.map((el, index) => {
        return {
          id: el.id,
          accountNumber: el.accountNumber,
          accountName: el.accountName,
          remark: el.remark,
          debitAmount: Number(el.debitAmount) === 0 ? null : el.debitAmount,
          creditAmount: Number(el.creditAmount) === 0 ? null : el.creditAmount,
          order: index + 1
        }
      })
      this.isLoading = false
    },
    informationProp() {
      this.isLoading = true
      const i = this.informationProp
      this.totalCredit = i.totalCredit
      this.totalDebit = i.totalDebit
      this.documentNumber = i.documentNumber
      this.isLoading = false
    },
    paginationInfoProp() {
      this.isLoading = true
      this.paginationInfo = this.paginationInfoProp
      this.isLoading = false
    }
  },
  methods: {
    paginationButtonClicked(position) {
      let item = null

      switch (position) {
        case 'FIRST':
          item = this.paginationInfo.first
          break
        case 'PREV':
          item = this.paginationInfo.prev
          break
        case 'NEXT':
          item = this.paginationInfo.next
          break
        case 'LAST':
          item = this.paginationInfo.last
          break
        default:
          break
      }

      if (item) {
        this.$emit('paginationSelected', {
          journalCode: item.journalCode,
          documentId: item.documentId
        })
      }
    },
    orderUpdatedHandler(payload) {
      this.$emit('orderUpdated', payload)
    }
  }
}
</script>

<template>
  <b-row align-h="center" class="mx-1 mx-xl-4">
    <b-col cols="12" class="bg-light pb-4" style="border-radius: 10px;">
      <!-- table part -->
      <account-transaction-table
        mode="VIEW"
        :informationProp="{ totalCredit: totalCredit, totalDebit: totalDebit }"
        :detailsProp="tableDetails"
        @orderUpdated="orderUpdatedHandler"
      ></account-transaction-table>
      <!-- pagination part -->
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <b-button
            variant="primary"
            :disabled="
              !paginationInfo ? true : paginationInfo.first ? false : true
            "
            class="first-prev-style"
            size="sm"
            @click="paginationButtonClicked('FIRST')"
          >
            แรกสุด
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            :disabled="
              !paginationInfo ? true : paginationInfo.prev ? false : true
            "
            class="second-prev-style"
            size="sm"
            @click="paginationButtonClicked('PREV')"
          >
            ก่อนหน้า
          </b-button>
        </div>
        <div v-if="documentNumber" class="pagination-display-style">
          {{ documentNumber }}
        </div>
        <div>
          <b-button
            variant="primary"
            :disabled="
              !paginationInfo ? true : paginationInfo.next ? false : true
            "
            class="first-next-style"
            size="sm"
            @click="paginationButtonClicked('NEXT')"
          >
            ถัดไป
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            :disabled="
              !paginationInfo ? true : paginationInfo.last ? false : true
            "
            class="second-next-style"
            size="sm"
            @click="paginationButtonClicked('LAST')"
          >
            ท้ายสุด
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style scoped>
/* Table */
.table-div {
  overflow-x: auto;
}
.table-header {
  display: flex;
}
.table-body-row {
  display: flex;
  flex-direction: column;
  max-height: 225px;
  width: fit-content;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.table-body-row::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
@media (min-width: 1200px) {
  .table-body-row {
    width: 100%;
  }
}
.table-body-row:hover {
  cursor: pointer;
}
.table-body-column {
  display: flex;
}
.table-footer {
  display: flex;
}
/* Pagination */
.first-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.first-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.pagination-display-style {
  border: 0px solid #dee2e6;
  height: 100%;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: not-allowed;
}
</style>
