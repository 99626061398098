<script>
import { mapState } from 'vuex'

import DocumentDeleteModal from './DocumentDeleteModal.vue'
import ExportPdfByPdfMake from './ExportPdfByPdfMake.vue'

export default {
  components: {
    DocumentDeleteModal,
    ExportPdfByPdfMake
  },
  props: {
    headerDataProp: Object,
    informationDataProp: Object,
    isDeleteOperationLoadingProp: Boolean
  },
  data() {
    return {
      journalBookId: null,
      modalShow: false,
      currentModal: null,
      modalThemeColor: null,
      modalTitle: null,
      exportPdfClicked: false,
      fullScreenMode: document.fullscreen,
      deleteModalDisplayStatus: false,
      // style class
      buttonClass:
        'shadow m-1 ml-2 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2 text-sm'
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    editAndDeleteButtonEnabled() {
      return (
        !this.informationDataProp || Boolean(this.informationDataProp.deletedAt)
      )
    }
  },
  created() {
    this.journalBookId = this.$route.params.journalBookId
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CREATE':
          this.$router.replace(
            `/entry/ge2gjnl/journal-book/${this.journalBookId}/create`
          )
          break
        case 'UPDATE':
          const journalBookId = this.journalBookId
          const journalCode = this.headerDataProp.journalCode
          const documentId = this.informationDataProp.documentId
          this.$router.replace(
            `/entry/ge2gjnl/journal-book/${journalBookId}/edit/${journalCode}/${documentId}`
          )
          break
        case 'DELETE':
          this.deleteModalDisplayStatus = true
          break
        case 'EXPORT':
          this.exportPdfClicked = true
          break
        case 'EXIT':
          this.$router.back()
          break
      }
    },
    deleteConfirmedHandler() {
      this.$emit('deleteConfirmed')
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- create button -->
      <div v-if="user && user.permission.entryJournal.canAddNew">
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('CREATE')"
          v-b-tooltip.hover.bottom.v-success="'เพิ่มรายการ'"
        >
          <b-icon :class="`${iconClass}`" icon="plus"></b-icon>
          <span class="text-sm">สร้าง</span>
        </b-button>
      </div>

      <!-- edit button -->
      <div v-if="user && user.permission.entryJournal.canUpdate">
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขรายการ'"
          :disabled="editAndDeleteButtonEnabled"
        >
          <b-icon :class="`${iconClass}`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- delete button -->
      <div v-if="user && user.permission.entryJournal.canDelete">
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบรายการ'"
          :disabled="editAndDeleteButtonEnabled || isDeleteOperationLoadingProp"
        >
          <b-icon :class="`${iconClass}`" icon="trash"></b-icon>
          <b-spinner
            v-if="isDeleteOperationLoadingProp"
            variant="light"
            small
          ></b-spinner>
          <span v-else class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- print report button -->
      <div v-if="user && user.permission.entryJournal.canPrint">
        <b-button
          :class="buttonClass"
          variant="info"
          @click="buttonClicked('EXPORT')"
          v-b-tooltip.hover.bottom.v-info="'ออกรายงาน'"
        >
          <b-icon :class="`${iconClass}`" icon="file-earmark-text"></b-icon>
          <span class="text-sm">พิมพ์</span>
        </b-button>
      </div>

      <!-- exit button -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon :class="`${iconClass}`" icon="box-arrow-right"></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- modal ยกเลิกเอกสาร -->
      <div>
        <document-delete-modal
          :headerDataProp="headerDataProp"
          :informationDataProp="informationDataProp"
          :deleteModalDisplayStatusProp="deleteModalDisplayStatus"
          @modalClosed="deleteModalDisplayStatus = false"
          @deleteConfirmed="deleteConfirmedHandler"
        ></document-delete-modal>
      </div>

      <!-- export pdf by pdf make -->
      <div class="d-none">
        <export-pdf-by-pdf-make
          :exportPdfClickedProp="exportPdfClicked"
          :journalBookIdProp="journalBookId"
          :documentIdProp="
            informationDataProp && informationDataProp.documentId
          "
          @pdfGenerated="exportPdfClicked = false"
        ></export-pdf-by-pdf-make>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
