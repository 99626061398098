<script>
import { isEmpty } from 'lodash'

import SelectDocumentModal from '@/components/modal/SelectDocumentModal'
import { toastMixins } from '@/mixins'
import journalService from '@/services/entry/ge2gjnl.js'

import DetailInput from './components/DetailInput'
import DetailTable from './components/DetailTable'
import MenuBar from './components/MenuBar'
import {
  journalBookImportAdapter,
  journalHeaderImportAdapter,
  journalDetailImportAdapter
} from '../helpers/adapter'

export default {
  mixins: [toastMixins],
  components: {
    MenuBar,
    DetailInput,
    DetailTable,
    SelectDocumentModal
  },
  data() {
    return {
      isDeleteOperationLoading: false,
      journalBookId: null,
      headerData: null,
      informationData: null,
      tableDetailsData: null,
      paginationData: null,
      showSelectDocumentModal: false,
      errMsg: null
    }
  },
  created() {
    this.journalBookId = this.$route.params.journalBookId
    this.fetchGe2gjnlDataDefault()
  },
  methods: {
    async fetchGe2gjnlDataDefault() {
      try {
        const res = await journalService.getGe2gjnlById(this.journalBookId)
        const {
          header,
          information,
          tableDetails,
          paginationInfo
        } = res.data.data
        this.headerData = journalBookImportAdapter(header)
        this.informationData = journalHeaderImportAdapter(information)
        this.tableDetailsData = tableDetails.map(detail =>
          journalDetailImportAdapter(detail)
        )
        this.paginationData = paginationInfo
      } catch (err) {
        console.log('fetchGe2gjnlDataDefault error!')
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงค่าเริ่มต้นรายการสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    async fetchGe2gjnlDataByJournalCodeAndDocumentId(value) {
      try {
        const { journalCode, documentId } = value
        const res = await journalService.getGe2gjnlDataByJournalCodeAndDocumentId(
          journalCode,
          documentId
        )
        const { information, tableDetails, paginationInfo } = res.data.data

        if (isEmpty(information)) {
          this.errMsg = 'ไม่พบเอกสารรายการนี้'
          return
        }

        this.informationData = journalHeaderImportAdapter(information)
        this.tableDetailsData = tableDetails.map(detail =>
          journalDetailImportAdapter(detail)
        )
        this.paginationData = paginationInfo
      } catch (err) {
        console.log('fetchGe2gjnlDataByJournalCodeAndDocumentId error!')
        const errMessage =
          err.response?.data.thMessage || 'ดึงรายการสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    async deleteConfirmedHandler() {
      this.isDeleteOperationLoading = true
      try {
        const journalCode = this.headerData.journalCode
        const documentNumber = this.informationData.documentNumber

        await journalService.deleteDocument(journalCode, documentNumber)
        await this.fetchGe2gjnlDataByJournalCodeAndDocumentId({
          journalCode,
          documentId: this.informationData.documentId
        })

        this.mxDisplaySuccessMessage('ลบรายการสมุดรายวันสำเร็จ')
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ลบรายการสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isDeleteOperationLoading = false
    },
    setShowSelectDocumentModal(value) {
      this.showSelectDocumentModal = value
    },
    journalSeletedHandler(value) {
      this.setShowSelectDocumentModal(false)
      this.fetchGe2gjnlDataByJournalCodeAndDocumentId(value)
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid="lg">
      <!-- menubar -->
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
          <span class="h2 text-white" style="text-shadow: 2px 2px grey;">
            รายการสมุดรายวัน
          </span>
        </b-col>
        <b-col class="col-12 col-xl-auto py-3 d-flex justify-content-center">
          <menu-bar
            :headerDataProp="headerData"
            :informationDataProp="informationData"
            :isDeleteOperationLoadingProp="isDeleteOperationLoading"
            @deleteConfirmed="deleteConfirmedHandler"
          ></menu-bar>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- header + information -->
      <b-row class="my-4">
        <b-col cols="12">
          <detail-input
            :headerProp="headerData"
            :informationProp="informationData"
            @documentSelectionClicked="setShowSelectDocumentModal(true)"
            @documentSelectionInput="fetchGe2gjnlDataByJournalCodeAndDocumentId"
          ></detail-input>
        </b-col>
      </b-row>

      <!-- details -->
      <b-row>
        <b-col cols="12">
          <detail-table
            :informationProp="informationData"
            :tableDetailsProp="tableDetailsData"
            :paginationInfoProp="paginationData"
            @paginationSelected="fetchGe2gjnlDataByJournalCodeAndDocumentId"
          ></detail-table>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal เลือกเอกสาร -->
    <div>
      <select-document-modal
        :displayModalProp="showSelectDocumentModal"
        @modalClosed="setShowSelectDocumentModal(false)"
        @journalSelected="journalSeletedHandler"
      ></select-document-modal>
    </div>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
