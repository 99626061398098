<script>
import { toastMixins } from '@/mixins'
import branchService from '@/services/branch/branch'

export default {
  mixins: [toastMixins],
  props: {
    headerProp: Object,
    informationProp: Object
  },
  data() {
    return {
      id: null,
      journalCode: null,
      journalName: null,
      documentIdPattern: {},
      documentNumber: null,
      documentId: null,
      documentDate: null,
      description: null,
      remark: null,
      isReverse: false,
      isPosted: false,
      isMockPosted: false,
      isDeleted: false,
      branchId: null,
      branchOptions: [],
      errMsg: null
    }
  },
  watch: {
    headerProp() {
      const h = this.headerProp
      this.id = h.id
      this.journalCode = h.journalCode
      this.journalName = h.journalName
      this.documentIdPattern = h.documentIdPattern
    },
    informationProp() {
      const i = this.informationProp
      this.documentNumber = i.documentNumber || null
      this.documentId = i.documentId || null
      this.documentDate = i.documentDate
        ? this.transformToBuddhistYear(i.documentDate)
        : null
      this.description = i.description || null
      this.remark = i.remark || null
      this.isReverse = !!i.isReverse
      this.isPosted = !!i.postedAt
      this.isMockPosted = !!i.isMockPosted
      this.isDeleted = !!i.deletedAt
      this.branchId = i.branchId
    }
  },
  created() {
    this.fetchBranchOptions()
  },
  methods: {
    async fetchBranchOptions() {
      try {
        const res = await branchService.getBranchOptions()
        this.branchOptions = res.data.data.map(el => ({ ...el }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    onDocumentSelectionClicked() {
      this.$emit('documentSelectionClicked')
    },
    transformToBuddhistYear(christianYear) {
      const dmy = christianYear.split('-')
      return `${dmy[2]}/${dmy[1]}/${+dmy[0] + 543}`
    },
    documentIdEnterHandler(e) {
      const item = {
        journalCode: this.journalCode,
        documentId: e.target.value
      }
      this.$emit('documentSelectionInput', item)
    },
    useStyles(style) {
      switch (style) {
        case 'div-class':
          return 'd-flex align-items-end mb-2'
        case 'label-style':
          return { width: '150px' }
        case 'short-form-style':
          return { width: '40%' }
        case 'long-form-style':
          return { width: '60%' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-row class="mx-1 mx-xl-4">
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px;">
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-center justify-content-md-start"
          cols="12"
          md="6"
          lg="7"
          xl="8"
        >
          <b-button variant="primary" class="mr-3 px-5" disabled>
            <span v-if="headerProp">{{ journalCode }}</span>
            <b-spinner v-else variant="light" small></b-spinner>
          </b-button>
          <span>{{ journalName }}</span>
        </b-col>
      </b-row>
      <hr />

      <!-- documentId / documentDate -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              เลขที่เอกสาร
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('short-form-style')"
              v-model="documentId"
              @keypress.enter="documentIdEnterHandler"
            ></b-form-input>
            <b-button variant="primary" @click="onDocumentSelectionClicked()">
              ...
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              วันที่เอกสาร
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('short-form-style')"
              v-model="documentDate"
              :disabled="true"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- documentNumber / description -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              เลขที่อ้างอิง
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="documentNumber"
              :disabled="true"
            ></b-form-input>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              อ้างอิง
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="description"
              :disabled="true"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- branch / remark -->
      <b-row>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              สาขา
            </label>
            <b-form-select
              class="mr-3"
              :style="{ ...useStyles('long-form-style'), color: 'black' }"
              v-model="branchId"
              :options="branchOptions"
              :disabled="true"
            ></b-form-select>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div :class="useStyles('div-class')">
            <label :style="useStyles('label-style')">
              หมายเหตุ
            </label>
            <b-form-input
              class="mr-3"
              :style="useStyles('long-form-style')"
              v-model="remark"
              :disabled="true"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>

      <!-- isDeleted / isPosted / isMockPosted / isReverse -->
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end mt-2 mt-lg-3 mb-1">
          <div v-if="isDeleted" class="font-weight-bold text-danger pr-4">
            <span>( ยกเลิก )</span>
          </div>
          <div v-else-if="isPosted" class="font-weight-bold text-success pr-4">
            <span>( ผ่านรายการแล้ว )</span>
          </div>
          <div v-else-if="isMockPosted" class="font-weight-bold pr-4">
            <span>( จำลองผ่านรายการ )</span>
          </div>
          <b-form-checkbox v-model="isReverse" class="pr-3" :disabled="true">
            กลับรายการ
          </b-form-checkbox>
        </b-col>
      </b-row>

      <error-modal
        :displayProp="!!errMsg"
        :errorMessageProp="errMsg"
        @modalClosed="errMsg = null"
      ></error-modal>
    </b-col>
  </b-row>
</template>

<style></style>
