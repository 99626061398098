<script>
import { isEmpty, times } from 'lodash'
import pdfMake from 'pdfmake'
import { mapState } from 'vuex'

import pdfFonts from '@/assets/fonts/custom-fonts.js'
import ge2gjnlService from '@/services/entry/ge2gjnl.js'
import branchService from '@/services/branch/branch.js'
import { toastMixins, datetimeMixins, numberMixins } from '@/mixins'

import {
  journalCompanyImportAdapter,
  journalBookImportAdapter,
  journalHeaderImportAdapter,
  journalDetailImportAdapter
} from '../../helpers/adapter'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

export default {
  mixins: [toastMixins, datetimeMixins, numberMixins],
  props: {
    exportPdfClickedProp: Boolean,
    journalBookIdProp: String,
    documentIdProp: String
  },
  data() {
    return {
      branchOptions: [],
      journalDocumentReportData: {},
      docDefinition: {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [25, 25],
        info: {
          title: 'รายงานเอกสารสมุดรายวัน',
          author: 'happysoft acccounting co., ltd'
        },
        defaultStyle: {
          font: 'THSarabunNew',
          fontSize: 15
        },
        content: []
      },
      currentRowNo: 0,
      numRowLimitPerPage: 17,
      currentPageNum: 0,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  watch: {
    exportPdfClickedProp() {
      if (this.exportPdfClickedProp) {
        this.startGeneratePdfReport()
      }
    }
  },
  methods: {
    async startGeneratePdfReport() {
      try {
        const resJournalDocument = await this.fetchJournalDocumentReportData()
        const {
          company,
          header,
          information,
          tableDetails
        } = resJournalDocument.data.data
        this.journalDocumentReportData = {
          company: journalCompanyImportAdapter(company),
          header: journalBookImportAdapter(header),
          information: journalHeaderImportAdapter(information),
          details: tableDetails.map(detail =>
            journalDetailImportAdapter(detail)
          )
        }

        const resBranches = await this.fetchBranchOptions()
        this.branchOptions = resBranches.data.data

        this.generatePdfReport()
      } catch (err) {
        console.log(err)
        this.errMsg = err.response.data.thMessage
      }
    },
    fetchJournalDocumentReportData() {
      const params = {
        journalBookId: this.$route.params.journalBookId,
        documentId: this.documentIdProp
      }
      return ge2gjnlService.getDataForGenerateJournalDocumentReport(params)
    },
    fetchBranchOptions() {
      return branchService.getBranchOptions()
    },
    generatePdfReport() {
      if (
        this.exportPdfClickedProp &&
        !isEmpty(this.journalDocumentReportData)
      ) {
        if (this.journalDocumentReportData.details.length > 0) {
          this.journalDocumentReportData.details.forEach(detail => {
            if (this.checkEndofPage()) this.addContentHeader()
            this.addContentBody(detail)
          })
        } else {
          if (this.checkEndofPage()) this.addContentHeader()

          if (this.journalDocumentReportData.information.deletedAt) {
            this.addCancelWaterMark()
          }
        }

        this.fillEmptyContentBody()
        this.addContentBodySummary()
        this.addContentContentFooter()

        this.openPdfOnNewTab()
      }

      this.$emit('pdfGenerated')

      setTimeout(() => {
        this.resetReportData()
      }, this.defaultDelayMs)
    },
    checkEndofPage() {
      return this.currentRowNo % this.numRowLimitPerPage === 0
    },
    addContentHeader() {
      this.currentPageNum++

      const { company, header, information } = this.journalDocumentReportData

      const companyAndPageNumberColumn =
        this.journalDocumentReportData.details.length <= this.numRowLimitPerPage
          ? {
              text: company.name,
              fontSize: 18,
              bold: true,
              width: '70%'
            }
          : {
              columns: [
                {
                  text: company.name,
                  fontSize: 18,
                  bold: true,
                  width: '70%'
                },
                {
                  text: `หน้าที่ ${this.currentPageNum}`,
                  alignment: 'right',
                  width: '30%'
                }
              ]
            }

      this.docDefinition.content.push(
        companyAndPageNumberColumn,
        {
          text: `${company.address1} ${company.address2} ${company.province} ${company.postalCode}`
        },
        {
          columns: [
            {
              text: '',
              width: '70%'
            },
            {
              text: `เลขที่\t\t${information.documentId || '-'}`,
              width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: '',
              width: '70%'
            },
            {
              text: `วันที่\t\t ${this.mxConvertToDateBuddhistFormat(
                information.documentDate || '-'
              )}`,
              width: '*'
            }
          ]
        },
        {
          text: `สมุดรายวัน\t\t${header.journalName || '-'}`
        },
        {
          text: `รายละเอียด\t\t${information.remark || '-'}`
        },
        {
          text: `สาขา\t\t\t\t${this.getBranchPhrase(information.branchId) ||
            '-'}`,
          margin: [0, 0, 0, 10]
        },
        {
          table: {
            widths: [100, 1, '*', 1, 100, 1, 100],
            body: [
              [
                {
                  text: 'เลขที่บัญชี',
                  alignment: 'center',
                  border: [false, false, false, true]
                },
                { text: '', border: [false, false, false, false] },
                {
                  text: 'รายละเอียด',
                  alignment: 'center',
                  border: [false, false, false, true]
                },
                { text: '', border: [false, false, false, false] },
                {
                  text: 'เดบิท',
                  alignment: 'center',
                  border: [false, false, false, true]
                },
                { text: '', border: [false, false, false, false] },
                {
                  text: 'เครดิต',
                  alignment: 'center',
                  border: [false, false, false, true]
                }
              ]
            ]
          }
        }
      )
    },
    addContentBody(detail) {
      this.currentRowNo++

      const { accountNumber, accountName, debitAmount, creditAmount } = detail

      const body = {
        table: {
          widths: [100, 1, '*', 1, 100, 1, 100],
          body: [
            [
              {
                text: accountNumber,
                border: [false, false, false, false],
                alignment: 'left'
              },
              { text: '', border: [false, false, false, false] },
              {
                text: accountName,
                border: [false, false, false, false],
                alignment: 'left'
              },
              { text: '', border: [false, false, false, false] },
              {
                text:
                  Number(debitAmount) === 0
                    ? ' '
                    : this.mxNumeralThousandSeperate(debitAmount),
                border: [false, false, false, false],
                alignment: 'right'
              },
              { text: '', border: [false, false, false, false] },
              {
                text:
                  Number(creditAmount) === 0
                    ? ' '
                    : this.mxNumeralThousandSeperate(creditAmount),
                border: [false, false, false, false],
                alignment: 'right'
              }
            ]
          ]
        }
      }

      if (
        this.checkEndofPage() &&
        this.currentRowNo < this.journalDocumentReportData.details.length
      ) {
        body.pageBreak = 'after'
      }

      this.docDefinition.content.push(body)
    },
    fillEmptyContentBody() {
      const fillContentBodyInCurrentPage =
        this.currentRowNo % this.numRowLimitPerPage === 0
          ? this.numRowLimitPerPage
          : this.currentRowNo % this.numRowLimitPerPage
      const leftContentBodyInCurrentPage =
        this.numRowLimitPerPage - fillContentBodyInCurrentPage

      if (leftContentBodyInCurrentPage > 0) {
        times(leftContentBodyInCurrentPage).forEach(() => {
          this.addContentBody({
            accountNumber: '',
            accountName: '',
            creditAmount: '',
            debitAmount: ''
          })
        })
      }
    },
    addContentBodySummary() {
      this.currentRowNo++

      const { information } = this.journalDocumentReportData
      const noBorder = [false, false, false, false]
      const marginTopAndBottom = [0, 5, 0, 5]

      const el = {
        table: {
          widths: [250, 1, '*', 1, 100, 1, 100],
          body: [
            [
              {
                text: `( ${this.mxGetBahtText(
                  Number(information.totalDebit)
                )} )`,
                alignment: 'center',
                border: noBorder,
                margin: marginTopAndBottom,
                fontSize: 14
              },
              {
                text: '',
                border: noBorder,
                margin: marginTopAndBottom
              },
              {
                text: 'รวม',
                alignment: 'right',
                border: noBorder,
                margin: marginTopAndBottom
              },
              {
                text: '',
                border: noBorder,
                margin: marginTopAndBottom
              },
              {
                text: this.mxNumeralThousandSeperate(information.totalDebit),
                alignment: 'right',
                border: noBorder,
                margin: marginTopAndBottom
              },
              {
                text: '',
                border: noBorder,
                margin: marginTopAndBottom
              },
              {
                text: this.mxNumeralThousandSeperate(information.totalCredit),
                alignment: 'right',
                border: noBorder,
                margin: marginTopAndBottom
              }
            ]
          ]
        }
      }

      this.docDefinition.content.push(el)
    },
    addContentContentFooter() {
      const noBorder = [false, false, false, false]
      const marginTop = [0, 20, 0, 0]

      const el = {
        table: {
          widths: [170, 1, 170, 1, '*'],
          body: [
            [
              {
                text: 'ผู้จัดทำ\t________________________',
                alignment: 'left',
                margin: marginTop,
                border: noBorder
              },
              { text: '', margin: marginTop, border: noBorder },
              {
                text: 'ผู้ตรวจสอบ\t_____________________',
                alignment: 'left',
                margin: marginTop,
                border: noBorder
              },
              { text: '', margin: marginTop, border: noBorder },
              {
                text: [
                  'ได้รับเงินถูกต้องเรียบร้อย\n\n',
                  '_________________________\n',
                  '___ / ___ / ___\n',
                  'ผู้รับเงิน'
                ],
                alignment: 'center',
                margin: marginTop,
                border: noBorder,
                rowSpan: 2
              }
            ],
            [
              {
                text: 'ผู้รับรองถูกต้อง\t__________________',
                alignment: 'left',
                margin: marginTop,
                border: noBorder
              },
              { text: '', margin: marginTop, border: noBorder },
              {
                text: 'ผู้อนุมัติ\t________________________',
                alignment: 'left',
                margin: marginTop,
                border: noBorder
              },
              { text: '', margin: marginTop, border: noBorder },
              {}
            ]
          ]
        }
      }

      this.docDefinition.content.push(el)
    },
    addCancelWaterMark() {
      this.docDefinition.watermark = {
        text: 'ยกเลิก',
        color: 'red',
        opacity: 0.2,
        bold: true,
        angle: -45
      }
    },
    openPdfOnNewTab() {
      pdfMake.createPdf(this.docDefinition).open()
    },
    getBranchPhrase(branchId) {
      const branch = this.branchOptions.find(branch => branch.id === branchId)
      if (!branchId || !branch) return null

      return branch.text
    },
    resetReportData() {
      this.branchOptions = []
      this.journalDocumentReportData = {}
      this.docDefinition.content = []
      this.docDefinition.watermark = undefined
      this.currentRowNo = 0
      this.numRowLimitPerPage = 17
      this.currentPageNum = 0
    }
  }
}
</script>

<template>
  <div>
    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
