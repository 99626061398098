<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="เลือกเอกสาร"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <b-table
      hover
      outlined
      bordered
      sticky-header="400px"
      :fields="tableHeaders"
      :items="tableContents"
      :busy="isLoading"
      thead-tr-class="text-center text-nowrap"
      tbody-tr-class="text-nowrap"
      selectable
      select-mode="single"
      @row-selected="rowSelectedHandler"
      @row-dblclicked="rowDoubleClickedHandler"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" variant="primary"></b-spinner>
          <strong>กำลังโหลดข้อมูล ...</strong>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center mt-2 mt-lg-0"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <b-button
              :variant="first ? 'primary' : 'secondary'"
              :disabled="!first"
              @click="paginationButtonClicked(first)"
              class="first-prev-style"
              size="sm"
            >
              แรกสุด
            </b-button>
          </div>
          <div>
            <b-button
              :variant="prev ? 'primary' : 'secondary'"
              :disabled="!prev"
              @click="paginationButtonClicked(prev)"
              class="second-prev-style"
              size="sm"
            >
              ก่อนหน้า
            </b-button>
          </div>
          <!-- <div v-if="page > 0" class="pagination-display-style">
            หน้าที่ {{ page }}
          </div> -->
          <div v-if="page > 0" class="">
            <b-dropdown
              id="dropdown-left"
              variant="primary"
              :text="`หน้าที่ ${page}`"
            >
              <div style="max-height: 150px; overflow-y: auto;">
                <b-dropdown-item
                  v-for="option in pageOptions"
                  :key="option.value"
                  @click="paginationButtonClicked(option.value)"
                  :disabled="page === option.value"
                >
                  {{ option.label }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
          <div>
            <b-button
              :variant="next ? 'primary' : 'secondary'"
              :disabled="!next"
              @click="paginationButtonClicked(next)"
              class="first-next-style"
              size="sm"
            >
              ถัดไป
            </b-button>
          </div>
          <div>
            <b-button
              :variant="last ? 'primary' : 'secondary'"
              :disabled="!last"
              @click="paginationButtonClicked(last)"
              class="second-next-style"
              size="sm"
            >
              ท้ายสุด
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <!-- sortType / search -->
      <b-row class="w-100 d-flex justify-content-between">
        <b-col cols="12" lg="6" class="d-flex align-items-center">
          <div class="w-100 d-flex">
            <b-form-select
              class="mr-2"
              v-model="sortBy"
              :options="sortByOptions"
            ></b-form-select>
            <b-form-select
              v-model="sortType"
              :options="sortTypeOptions"
            ></b-form-select>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="5"
          class="d-flex justify-content-end align-items-center mt-3 mt-lg-0"
        >
          <span class="mr-3">ค้นหา</span>
          <div class="w-100">
            <b-form-input
              v-model="searchKeyword"
              :placeholder="getSearchKeywordInputPlaceHolder()"
            ></b-form-input>
          </div>
          <div class="ml-3">
            <b-button
              :variant="rowSelected ? 'primary' : 'secondary'"
              :disabled="!rowSelected"
              @click="selectedButtonClicked()"
            >
              เลือก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import journalService from '@/services/entry/ge2gjnl.js'

/*
Props: {
  displayModalProp: เปิดปิดการแสดง modal (type: boolean)
}
Events: {
  modalClosed: เมื่อ modal ถูกปิด (payload: undefined)
  journalSelected: เมื่อเลือกเอกสารได้แล้ว (payload: {
    amount: string
    description: string
    documentDate: string
    documentDateTHConverted: string
    journalCode: string
    journalNumber: string
    listAmountStatus: 66
    listPassSimulateStatus: string
    listReverseStatus: string
    listStatus: string
    passStatus: boolean
    printStatus: string
    remark: string | null
    simulateStatus: boolean
    startingPoint: string | null
  })
}
*/

export default {
  mixins: [toastMixins],
  props: {
    displayModalProp: Boolean
  },
  data() {
    return {
      modalShow: false,
      tableOriginalHeaders: [
        {
          key: 'documentId',
          attr: 'documentId',
          label: 'เลขที่เอกสาร'
        },
        {
          key: 'journalNumber',
          attr: 'gltjnth_journalNumber',
          label: 'เลขที่อ้างอิง'
        },
        { key: 'journalCode', attr: 'gltjnth_journalCode', label: 'สมุด' },
        { key: 'description', attr: 'gltjnth_description', label: 'อ้างถึง' },
        { key: 'remark', attr: 'gltjnth_remark', label: 'หมายเหตุ' },
        {
          key: 'documentDateTHConverted',
          attr: 'gltjnth_documentDate',
          label: 'วันที่'
        },
        {
          key: 'listPassSimulateStatus',
          attr: 'list_pass_simulate_status',
          label: 'สถานะ'
        },
        // Old version
        // { key: 'listStatus', attr: 'list_status', label: 'รายการ' },
        // { key: 'passStatus', attr: 'pass_status', label: 'ผ่าน' },
        // {
        //   key: 'simulateStatus',
        //   attr: 'simulate_status',
        //   label: 'จำลอง'
        // },
        { key: 'printStatus', attr: 'print_status', label: 'พิมพ์' },
        { key: 'amount', attr: 'amount', label: 'จำนวนเงิน' },
        {
          key: 'listReverseStatus',
          attr: 'list_reverse_status',
          label: 'กลับรายการ'
        },
        {
          key: 'startingPoint',
          attr: 'starting_point',
          label: 'ต้นทาง'
        },
        {
          key: 'listAmountStatus',
          attr: 'list_amount',
          label: 'นับรายการ'
        }
      ],
      tableHeaders: [],
      sortTypeOptions: [
        { value: 'desc', text: 'เรียงแบบ ท้ายสุด - แรกสุด' },
        { value: 'asc', text: 'เรียงแบบ แรกสุด - ท้ายสุด' }
      ],
      sortByOptions: [
        { value: 'documentId', text: 'เรียงตาม เลขที่เอกสาร' },
        { value: 'gltjnth_journalNumber', text: 'เรียงตาม เลขที่อ้างอิง' },
        { value: 'gltjnth_documentDate', text: 'เรียงตาม วันที่' },
        { value: 'starting_point', text: 'เรียงตาม ต้นทาง' }
      ],
      tableContents: [],
      isLoading: false,
      searchKeyword: '',
      searchKeywordTimer: null,
      rowSelected: null,
      page: 1,
      pageOptions: [],
      limit: 50,
      sortType: 'desc',
      sortBy: 'documentId',
      first: false,
      prev: false,
      next: false,
      last: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      stateConstantsDefaultKeywordTimerMs: state =>
        state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    displayModalProp() {
      this.modalShow = this.displayModalProp
      if (this.modalShow) {
        this.fetchDocuments()
      } else {
        this.resetModalData()
      }
    },
    modalShow() {
      if (!this.modalShow) {
        this.$emit('modalClosed')
      }
    },
    sortType() {
      this.first = false
      this.prev = false
      this.next = false
      this.last = false
      this.page = 1
      this.pageOptions = [{ value: 1, label: 'หน้าที่ 1' }]
      this.fetchDocuments()
    },
    sortBy() {
      this.first = false
      this.prev = false
      this.next = false
      this.last = false
      this.page = 1
      this.pageOptions = [{ value: 1, label: 'หน้าที่ 1' }]
      this.editTableHeadersWhenSortByChanged(this.sortBy)
      this.fetchDocuments()
    },
    searchKeyword() {
      if (this.searchKeywordTimer) {
        clearTimeout(this.searchKeywordTimer)
      }
      const timer = setTimeout(() => {
        this.first = false
        this.prev = false
        this.next = false
        this.last = false
        this.page = 1
        this.pageOptions = [{ value: 1, label: 'หน้าที่ 1' }]
        this.fetchDocuments()
        this.searchKeywordTimer = null
      }, this.stateConstantsDefaultKeywordTimerMs)
      this.searchKeywordTimer = timer
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.isLoading = true
      this.tableHeaders = this.tableOriginalHeaders.map(el => ({ ...el }))
      this.fetchDocuments()
      this.isLoading = false
    },
    async fetchDocuments() {
      this.isLoading = true
      try {
        const querystrings = [
          `sortType=${this.sortType}`,
          `limit=${this.limit}`,
          `page=${this.page}`,
          `sortBy=${this.sortBy}`
        ]
        if (this.searchKeyword.trim().length > 0) {
          if (this.sortBy !== 'gltjnth_documentDate') {
            querystrings.push(`keyword=${this.searchKeyword.trim()}`)
          } else if (this.sortBy === 'gltjnth_documentDate') {
            const ddmmyy = this.searchKeyword.trim().split('/')
            const day =
              ddmmyy.length >= 1
                ? ddmmyy[0].trim().length > 0
                  ? ddmmyy[0].trim()
                  : '00'
                : '00'
            const month =
              ddmmyy.length >= 2
                ? ddmmyy[1].trim().length > 0
                  ? ddmmyy[1].trim()
                  : '00'
                : '00'
            const year =
              ddmmyy.length >= 3
                ? ddmmyy[2].trim().length > 0
                  ? +ddmmyy[2].trim() - 543
                  : '0000'
                : '0000'
            querystrings.push(`keyword=${year}-${month}-${day}`)
          }
        }
        const res = await journalService.getDocumentsById(
          this.$route.params.journalBookId,
          querystrings.join('&')
        )
        const { data, first, next, last, prev } = res.data.data
        this.formatTableContents(data)
        this.first = first
        this.prev = prev
        this.next = next
        this.last = last
        this.pageOptions = _.times(_.max([first, this.page, last])).map(
          num => ({
            value: num + 1,
            label: `หน้าที่ ${num + 1}`
          })
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลเอกสารไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    selectedButtonClicked() {
      this.$emit('journalSelected', this.rowSelected)
    },
    formatTableContents(contents) {
      this.tableContents = contents.map(content => {
        return {
          journalCode: content.gltjnth_journalCode,
          journalNumber: content.gltjnth_journalNumber,
          documentId: content.documentId,
          description: content.gltjnth_description,
          remark: content.gltjnth_remark,
          documentDate: content.gltjnth_documentDate,
          documentDateTHConverted: (() => {
            let yymmdd = content.gltjnth_documentDate.split('-')
            return `${yymmdd[2]}/${yymmdd[1]}/${+yymmdd[0] + 543}`
          })(),
          listStatus: content.list_status,
          passStatus: content.pass_status,
          simulateStatus: content.simulate_status,
          listPassSimulateStatus:
            content.list_status === 'Delete'
              ? 'ยกเลิก'
              : content.pass_status
              ? 'ผ่าน'
              : content.simulate_status
              ? 'จำลอง'
              : '-',
          printStatus: content.print_status ? '-' : 'ยัง',
          amount: content.amount,
          listReverseStatus: content.list_reverse_status ? 'กลับ' : '-',
          startingPoint: content.starting_point,
          listAmountStatus: content.list_amount
        }
      })
    },
    rowSelectedHandler(e) {
      if (e.length > 0) this.rowSelected = e[0]
      else this.rowSelected = null
    },
    resetModalData() {
      this.tableContents = []
      this.isLoading = false
      this.searchKeyword = ''
      this.rowSelected = null
      this.sortType = 'desc'
      this.sortBy = 'documentId'
    },
    paginationButtonClicked(page) {
      this.page = page
      this.fetchDocuments()
    },
    rowDoubleClickedHandler(e) {
      this.rowSelected = e
      this.selectedButtonClicked()
    },
    editTableHeadersWhenSortByChanged(target) {
      const copiedTableHeaders = this.tableOriginalHeaders.map(el => ({
        ...el
      }))
      const sortByObject = copiedTableHeaders.find(el => el.attr === target)
      const updatedTableHeaders = [sortByObject]
      copiedTableHeaders.forEach(el => {
        if (el.attr !== target) {
          updatedTableHeaders.push({ ...el })
        }
      })
      this.tableHeaders = updatedTableHeaders
    },
    getSearchKeywordInputPlaceHolder() {
      let placeholder = this.sortByOptions
        .find(el => el.value === this.sortBy)
        .text.split(' ')[1]
      if (placeholder === 'วันที่') {
        placeholder = 'วว/ดด/ปปปป'
      }
      return placeholder
    }
  }
}
</script>

<style scoped>
/* Pagination */
.first-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.first-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.pagination-display-style {
  border: 0px solid #dee2e6;
  height: 100%;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: not-allowed;
  min-width: 50px;
  text-align: center;
}
</style>
