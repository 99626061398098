import client from '../api'

const endpoint = '/branch'

export default {
  getBranchOptions: async () => {
    return await client.get(`${endpoint}/options`)
  },
  getBranchTree: async () => {
    return await client.get(`${endpoint}/tree`)
  },
  createNewBranch: async body => {
    return await client.post(`${endpoint}`, body)
  },
  updateBranchById: async (id, body) => {
    return await client.patch(`${endpoint}/${id}`, body)
  },
  deleteBranchById: async id => {
    return await client.patch(`${endpoint}/${id}/delete`)
  }
}
